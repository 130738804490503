import React from 'react'

import LayoutOB from '../components/layoutob.js'
import { useLocation } from '@reach/router'
import styled from "styled-components"

import FormAuth from '../components/Form/AuthForm'
import { deDE } from "../lang/de-DE"
import { deCH } from "../lang/de-CH"

const Wrapper = styled.div`
display:flex;
justify-content:flex-start;
align-items:flex-start;
flex: 1 0 auto;
min-height: 80vh;
`
export default function VollmachtPage() {

    const location = useLocation();
    var searchParams = new URLSearchParams(location.search);
    var countryParam = searchParams.get("country");
    let txt, language;

    if (countryParam === "DE") {
        txt = deDE.AuthForm
        language = "de-DE"
    }
    else {
        txt = deCH.AuthForm
        language = "de-CH"
    }

    return (
        <LayoutOB location={countryParam} language={language}>
            <Wrapper>
                <FormAuth country={countryParam} txt={txt} />
            </Wrapper>
        </LayoutOB>
    )
}


//<FormPage/>