import React, { useState, useEffect } from "react"
import {
  TextField,
  Typography,
  Grid,
  ThemeProvider,
  makeStyles,
  Button,
} from "@material-ui/core"
import { Link } from "gatsby"
import theme from "../theme"
import SpinnerButton from "../../BasicUiElements/SpinnerButton"
import "../../../components/globalStyles.css"

const useStyles = makeStyles(theme => ({
  formHeader: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorMessage: {
    color: "#FF3333",
    fontSize: "1rem",
    fontWeight: 300,
    textAlign: "center",
  },
  textCentral:{
    textAlign:"center"
  }
}))

/**
 *
 * @param {TypeProp} props
 * @returns {Object} React component
 */
export default function Login(props) {
  const [form, setForm] = useState({
    email: "",
    password: "",
  })
  const [isFormValid, setIsFormValid] = useState(false)
  const [stateErrorMessage, setStateErrorMessage] = useState({
    email: "",
    password: "",
  })

  const [error, setError] = useState()
  const [busy, setBusy]=useState(false)
  const classes = useStyles()

  const mailRegex = new RegExp(/.+@.+\..+/)

  useEffect(() => {
    setForm({ ...form, ["email"]: props.email })
  }, [])

  //validate if form is completed and next button should be activated
  useEffect(() => {
    setIsFormValid(mailRegex.test(form.email) && form.password !== "")
  }, [form.email, form.password])

  const handleOnLoginClicked = () => {
    setBusy(true)
    const alreadyLoggedIn = props.firebase.isUserLoggedIn()
    if (!alreadyLoggedIn) {
      props.firebase
        .doSignInWithEmailAndPassword(form.email, form.password)
        .then(() => {props.handleNext()
        setBusy(false)})
        .catch(err => {
          if (err.code === "auth/wrong-password") {
            setError(props.txt.errorWrongPassword)
          } else if (err.code === "auth/too-many-requests") {
            setError(props.txt.errorTooManyRequests)
          } else if (err.code === "auth/user-not-found") {
            setError(props.txt.errorWrongPassword)
          } else {
            setError(props.errorUnknown)
          }
          setBusy(false)
        })
    } else {
      props.firebase
        .doSignOut()
        .then(() => {
          props.firebase
            .doSignInWithEmailAndPassword(form.email, form.password)
            .then(() => {props.handleNext()
              setBusy(false)})
            .catch(err => {
              if (err.code === "auth/wrong-password") {
                setError(props.txt.errorWrongPassword)
              } else {
                setError(props.errorUnknown)
              }
              setBusy(false)
            })
        })
        .catch(err => {
          setError(err.message)
        })
    }
  }

  const handleChange = e => {
    //update form state
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const validateInput = name => {
    // validate Input
    switch (name) {
      case "email":
        if (form.email === "") {
          setStateErrorMessage({
            ...stateErrorMessage,
            ["email"]: props.txt.errorValueNeeded,
          })
        } else if (!mailRegex.test(form.email)) {
          setStateErrorMessage({
            ...stateErrorMessage,
            ["email"]: props.txt.errorEmail,
          })
        } else {
          setStateErrorMessage({ ...stateErrorMessage, ["email"]: "" })
        }
        break
      case "password":
        if (form.password === "") {
          setStateErrorMessage({
            ...stateErrorMessage,
            ["password"]: props.txt.errorValueNeeded,
          })
        } else {
          setStateErrorMessage({ ...stateErrorMessage, ["password"]: "" })
        }
        break
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.formHeader}>
              {" "}
              {props.txt.login}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              type="email"
              error={stateErrorMessage.email !== "" ? true : false}
              variant="outlined"
              name="email"
              label={props.txt.labelEmail}
              helperText={stateErrorMessage.email}
              onChange={handleChange}
              onBlur={() => validateInput("email")}
              value={form.email}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="password"
              type="password"
              error={stateErrorMessage.password !== "" ? true : false}
              variant="outlined"
              name="password"
              label={props.txt.labelPassword}
              helperText={stateErrorMessage.password}
              onChange={handleChange}
              value={form.password}
              onBlur={() => validateInput("password")}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <div className={classes.buttons}>
              <SpinnerButton
                variant="contained"
                color="primary"
                loading={busy}
                onClick={handleOnLoginClicked}
                className={classes.button}
                disabled={!isFormValid || busy}
              >
                {props.txt.btnLoginTxt}
              </SpinnerButton >
              {error && (
                <div>
                  <Typography variant="body1" className={classes.errorMessage}>
                    {error}
                  </Typography>
                </div>
              )}
            </div>
            <div className={classes.textCentral}>
            <Typography variant="body1"><Link to="/passwortreset">{props.txt.forgottenPassword}</Link> </Typography>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  )
}
