import React, { useState, useEffect } from "react"
import { navigate } from "@reach/router"
import { withFB } from "../../Firebase/firebasecontext"
import { makeStyles } from "@material-ui/core/styles"
import * as ROUTE from "../Links"
import theme from "../theme"

import {
  Typography,
  ThemeProvider,
  Box,
  Grid,
  Button,
  TextField,
} from "@material-ui/core"



const useStyles = makeStyles(theme => ({
  formHeader: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  buttonsVertical: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(3),
  },
  buttonsHorizontal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  minimalMarginPadding: {
    marginTop: 0,
    marginBottom: 0,
    padding: theme.spacing(0),
  },

  buttonShort: {
    margin: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      minWidth: "256px",
    },
  },
  buttonWide: {
    margin: theme.spacing(1),
    width: "100%",
  },
  errorMessage: {
    color: "#FF3333",
    fontSize: "1rem",
    fontWeight: 300,
    textAlign: "center",
  },
}))

const errorMessages = {
  physician1Name: "",
  physician1Location: "",
  physician2Name: "",
  physician2Location: "",
  physician3Name: "",
  physician3Location: "",
  physician4Name: "",
  physician4Location: "",
  physician5Name: "",
  physician5Location: "",
}

function FormAuthorizationLetterRaw(props) {
  const classes = useStyles()
  const [physicianNumber, setPhysicianNumber] = useState(0)
  const [isValid, setIsValid] = useState(false)
  const [showPhysicians, setShowPhysicians] = useState(false)
  const [form, setForm] = useState({
    physician1Name: "",
    physician1Location: "",
  })

  let RouteAuth

    if(props.country==="DE"||props.country==="de"){
      RouteAuth=ROUTE.AuthFormDe
    } else{
      RouteAuth= ROUTE.AuthFormChDe
    }


  const handleStartDocument = () => {
    props.firebase
      .doAddPhysiciansSalesInfo(form)
      .then(() => {
        navigate(
          RouteAuth
        )
      })
      .catch(err => console.log("Error: " + err.message))
  }

  const handleMorePhysician = () => {
    if (physicianNumber < 4) {
      setPhysicianNumber(prevPhysicianNumber => prevPhysicianNumber + 1)
    }
  }

  const handleLessPhysician = () => {
    if (physicianNumber > 0) {
      setPhysicianNumber(prevPhysicianNumber => prevPhysicianNumber - 1)
    }
  }

  useEffect(() => {
    switch (physicianNumber) {
      case 0:
        if (form.physician1Name !== "" && form.physician1Location !== "") {
          setIsValid(true)
        } else {
          setIsValid(false)
        }
        break
      case 1:
        if (
          form.physician1Name !== "" &&
          form.physician1Location !== "" &&
          form.physician2Name !== "" &&
          form.physician2Location !== ""
        ) {
          setIsValid(true)
        } else {
          setIsValid(false)
        }
        break
      default:
    }
  }, [form, physicianNumber])

  const handleChange = e => {
    //update form state and handle validation

    setForm({ ...form, [e.target.name]: e.target.value })

    switch (e.target.name) {
      case "salesChannel":
        errorMessages.salesChannel =
          e.target.value === "" ? props.txt.errorValueNeeded : ""
        break
      case "salesChannelOther":
        errorMessages.salesChannelOther =
          e.target.value === "" ? props.txt.errorValueNeeded : ""
        break
      default:
        break
    }
  }

  return (
    <ThemeProvider theme={theme}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              className={classes.formHeader}
              dangerouslySetInnerHTML={{ __html: props.txt.PhysicianTitle }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: props.txt.PhysicianBody1 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              {props.txt.PhysicianName} 1
            </Typography>
            <TextField
              required
              error={errorMessages.physician1Name !== "" ? true : false}
              variant="outlined"
              name="physician1Name"
              label={props.txt.labelPhysicianName}
              helperText={errorMessages.physicianName}
              fullWidth
              onChange={handleChange}
              value={form.physician1Name}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              error={errorMessages.physician1Location !== "" ? true : false}
              variant="outlined"
              name="physician1Location"
              label={props.txt.labelPhysicianLocation}
              helperText={errorMessages.physicianLocation}
              fullWidth
              onChange={handleChange}
              value={form.physician1Location}
            />
          </Grid>

          {physicianNumber > 0 && (
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                {props.txt.PhysicianName} 2
              </Typography>
              <TextField
                error={errorMessages.physician2Name !== "" ? true : false}
                variant="outlined"
                name="physician2Name"
                label={props.txt.labelPhysicianName}
                helperText={errorMessages.physicianName}
                fullWidth
                onChange={handleChange}
                value={form.physician2Name}
              />
            </Grid>
          )}
          {physicianNumber > 0 && (
            <Grid item xs={12}>
              <TextField
                error={errorMessages.physician2Location !== "" ? true : false}
                variant="outlined"
                name="physician2Location"
                label={props.txt.labelPhysicianLocation}
                helperText={errorMessages.physicianLocation}
                fullWidth
                onChange={handleChange}
                value={form.physician2Location}
              />
            </Grid>
          )}
          {physicianNumber > 1 && (
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                {props.txt.PhysicianName} 3
              </Typography>
              <TextField
                error={errorMessages.physician3Name !== "" ? true : false}
                variant="outlined"
                name="physician3Name"
                label={props.txt.labelPhysicianName}
                helperText={errorMessages.physicianName}
                fullWidth
                onChange={handleChange}
                value={form.physician3Name}
              />
            </Grid>
          )}
          {physicianNumber > 1 && (
            <Grid item xs={12}>
              <TextField
                error={errorMessages.physician3Location !== "" ? true : false}
                variant="outlined"
                name="physician3Location"
                label={props.txt.labelPhysicianLocation}
                helperText={errorMessages.physicianLocation}
                fullWidth
                onChange={handleChange}
                value={form.physician3Location}
              />
            </Grid>
          )}
          {physicianNumber > 2 && (
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                {props.txt.PhysicianName} 4
              </Typography>
              <TextField
                error={errorMessages.physician4Name !== "" ? true : false}
                variant="outlined"
                name="physician4Name"
                label={props.txt.labelPhysicianName}
                helperText={errorMessages.physicianName}
                fullWidth
                onChange={handleChange}
                value={form.physician4Name}
              />
            </Grid>
          )}
          {physicianNumber > 2 && (
            <Grid item xs={12}>
              <TextField
                error={errorMessages.physician4Location !== "" ? true : false}
                variant="outlined"
                name="physician4Location"
                label={props.txt.labelPhysicianLocation}
                helperText={errorMessages.physicianLocation}
                fullWidth
                onChange={handleChange}
                value={form.physician4Location}
              />
            </Grid>
          )}
          {physicianNumber > 3 && (
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                {props.txt.PhysicianName} 5
              </Typography>
              <TextField
                error={errorMessages.physician5Name !== "" ? true : false}
                variant="outlined"
                name="physician5Name"
                label={props.txt.labelPhysicianName}
                helperText={errorMessages.physicianName}
                fullWidth
                onChange={handleChange}
                value={form.physician5Name}
              />
            </Grid>
          )}
          {physicianNumber > 3 && (
            <Grid item xs={12}>
              <TextField
                error={errorMessages.physician5Location !== "" ? true : false}
                variant="outlined"
                name="physician5Location"
                label={props.txt.labelPhysicianLocation}
                helperText={errorMessages.physicianLocation}
                fullWidth
                onChange={handleChange}
                value={form.physician5Location}
              />
            </Grid>
          )}

          <Grid item xs={12} className={classes.minimalMarginPadding}>
            <Box display="flex" justifyContent="space-between">
              <Button
                variant="text"
                color="primary"
                onClick={handleLessPhysician}
                className={classes.buttonShort}
                disabled={physicianNumber === 0}
              >
                {props.txt.btnRemovePhysicians}
              </Button>

              <Button
                variant="outlined"
                color="primary"
                onClick={handleMorePhysician}
                className={classes.buttonShort}
                disabled={physicianNumber === 4}
              >
                {props.txt.btnAddPhysicians}
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.buttonsVertical}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleStartDocument}
                className={classes.buttonWide}
                disabled={!isValid}
              >
                {props.txt.btnOpenAuthForm}
              </Button>
            </div>
          </Grid>
        </Grid>
    </ThemeProvider>
  )
}

const FormAuthorizationLetter = withFB(FormAuthorizationLetterRaw)

export default FormAuthorizationLetter
