import React, { useState } from "react"
import { navigate } from "@reach/router"
import { ThemeProvider } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"

import { withFB } from "../../Firebase/firebasecontext"

import useSharedStyles from "../FormStyles"
import theme from "../theme"
import logo from "../../../../src/images/DoryGo_logo.png"

import Login from "./Login"
import AuthorizationLetter from "./AuthorizationLetter"

import * as ROUTE from "../Links"


/**
 * Description of FormWeb Functional component
 * @module FormWeb
 * @param props
 * @returns {Object} Returns a stepper that wraps sections to collect data from users: personal data(new user) or login data(existing user), and delivery info.
 */
function FormOnboardingBase(props) {
  const classes = useSharedStyles()
  console.log(props.language + "Form / AuthForm")

  const [steps, setSteps] = useState([
    "Login",
    "Vollmacht",

  ])
  const [activeStep, setActiveStep] = useState(0)
  const [error, setError] = useState()


  const handleNext = () => {
    props.firebase
      .doGetUserData()
      .then(doc => {
        if (doc) {
          props.handleUpdateCountry(doc.countryCode)
        } else {
          console.log("Error: country not available")
        }
      })
      .catch(err => setError(err.message))

    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Login
            handleNext={handleNext}
            txt={props.txt.Login}
            firebase={props.firebase}
          />
        )
      case 1:
        return (
          <AuthorizationLetter
            firebase={props.firebase}
            txt={props.txt.AuthorizationLetter}
            country={props.country}
            language={props.language}
          />
        )
      
      default:
        return "unknown stepIndex"
    }
  }

  //props.firebase.doGetUserLocation()

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.layout}>
          <Paper className={classes.paper}>
            <div className={classes.logoBox}>
              <a href="https://dorygo.com">
                <img src={logo} width="100%" className={classes.logo} />
              </a>

            </div>
            <div className={classes.noShow}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel />
                </Step>
              ))}
            </Stepper>
            </div>
            <div>{getStepContent(activeStep)}</div>
          </Paper>
        </div>
      </ThemeProvider>
    </>
  )
}

const FormOnboarding = withFB(FormOnboardingBase)

export default FormOnboarding
